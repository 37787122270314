
import { NgModule } from "@angular/core";
import { SortPipe } from "./sort";
import { BooleanPipe } from './boolean.pipe';
import { FilterBooleanPipe } from './filterboolean';


@NgModule({
    declarations: [SortPipe, BooleanPipe, FilterBooleanPipe],
    exports: [SortPipe, BooleanPipe, FilterBooleanPipe]
})
export class PipesModule {}  