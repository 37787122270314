import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../core/services/auth.service';
import {BaseService} from '../../pages/_services/base.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  providers: [BaseService]
})
export class TopbarComponent implements OnInit {

  notificationItems: Array<{}>;
  languages: Array<{
    id: number,
    flag?: string,
    name: string
  }>;
  selectedLanguage: {
    id: number,
    flag?: string,
    name: string
  };
  user;admin;username='profil';

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  constructor(private router: Router, private authService: AuthenticationService,
    private service: BaseService) { }

  ngOnInit() {
    // get the notifications
    this._fetchNotifications();
    this.openMobileMenu = false;

    this.user = this.authService.currentUser();
    if (this.user){
      this.service.user = this.user;
      if (this.user['firstName'] && this.user['firstName'] != '' && 
          this.user['lastName'] && this.user['lastName'] != '')
          this.username = this.user['firstName'] + ' ' + this.user['lastName'];
      else
      this.username = this.user['email'];   
      
      // if (this.user && this.user['type'] === '0')
      //   this.admin = true;
    }
  }


  /**
   * Change the language
   * @param language language
   */
  changeLanguage(language) {
    this.selectedLanguage = language;
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();
    this.router.navigate(['/account/login']);
  }

  /**
   * Fetches the notification
   * Note: For now returns the hard coded notifications
   */
  _fetchNotifications() {
    this.notificationItems = [];
  }

  // {
  //   text: 'Caleb Flakelar commented on Admin',
  //   subText: '1 min ago',
  //   icon: 'mdi mdi-comment-account-outline',
  //   bgColor: 'primary',
  //   redirectTo: '/notification/1'
  // },
  // {
  //   text: 'New user registered.',
  //   subText: '5 min ago',
  //   icon: 'mdi mdi-account-plus',
  //   bgColor: 'info',
  //   redirectTo: '/notification/2'
  // },
  // {
  //   text: 'Cristina Pride',
  //   subText: 'Hi, How are you? What about our next meeting',
  //   icon: 'mdi mdi-comment-account-outline',
  //   bgColor: 'success',
  //   redirectTo: '/notification/3'
  // },
  // {
  //   text: 'Caleb Flakelar commented on Admin',
  //   subText: '2 days ago',
  //   icon: 'mdi mdi-comment-account-outline',
  //   bgColor: 'danger',
  //   redirectTo: '/notification/4'
  // },
  // {
  //   text: 'Caleb Flakelar commented on Admin',
  //   subText: '1 min ago',
  //   icon: 'mdi mdi-comment-account-outline',
  //   bgColor: 'primary',
  //   redirectTo: '/notification/5'
  // },
  // {
  //   text: 'New user registered.',
  //   subText: '5 min ago',
  //   icon: 'mdi mdi-account-plus',
  //   bgColor: 'info',
  //   redirectTo: '/notification/6'
  // },
  // {
  //   text: 'Cristina Pride',
  //   subText: 'Hi, How are you? What about our next meeting',
  //   icon: 'mdi mdi-comment-account-outline',
  //   bgColor: 'success',
  //   redirectTo: '/notification/7'
  // },
  // {
  //   text: 'Caleb Flakelar commented on Admin',
  //   subText: '2 days ago',
  //   icon: 'mdi mdi-comment-account-outline',
  //   bgColor: 'danger',
  //   redirectTo: '/notification/8'
  // }
}
