let listmenu = [
    {
        title: 'ADMIN',
        category: 'admin',
        children: [
            {
                title: 'Import',
                code: 'import',
                category: 'admin',
                path: '/admin/import'
            },
            {
                title: 'Module pe utilizator',
                code: 'modules',
                category: 'admin',
                path: '/admin/modules'
            },
            {
                title: 'Module setari',
                code: 'modulesxsettings',
                category: 'admin',
                path: '/admin/modulesxsettings'
            },
            {
                title: 'Notificari',
                code: 'notifications',
                category: 'admin',
                path: '/admin/notificari'
            },
            {
                title: 'Aprobari',
                code: 'approv',
                category: 'admin',
                path: '/admin/approv'
            },
            {
                title: 'Flux',
                code: 'flux',
                category: 'admin',
                path: '/admin/flux'
            },
            {
                title: 'Constante tip',
                code: 'constantatip',
                category: 'admin',
                path: '/admin/constantatip',
                children: []
            },
            {
                title: 'Constante',
                code: 'constanta',
                category: 'admin',
                path: '/admin/constanta',
                children: []
            },
            {
                title: 'Comisii',
                code: 'comisii',
                category: 'admin',
                path: '/admin/comisii',
                children: []
            }
        ]
    },
    // {
    //     title: 'Program',
    //     category: 'personal',
    //     path: '/personal/program',
    // },
    // {
    //     title: 'Pontaj v1',
    //     category: 'personal',
    //     path: '/personal/pontaj',
    // },
    // {
    //     title: 'Pontaj v2',
    //     category: 'personal',
    //     path: '/personal/calendar',
    // },
    {
        title: 'Dashboard',
        code: 'dashboard',
        category: 'dashboard',
        path: '/dashboards/dashboard-1'
    },
    {
        title: 'Documentatie',
        code: 'help',
        category: 'help',
        path: '/documents/help'
    },
    {
        title: 'Modul Receptie',
        main: true,
        category: 'receptie',
        children: []
    },
    {
        title: 'Documente',
        category: 'receptie',
        children: [
            {
                title: 'Receptie',
                category: 'receptie',
                path: '/receptie/receptie'
            },
            {
                title: 'Proces verbal receptie',
                category: 'receptie',
                path: '/receptie/pvr'
            },
            // {
            //     title: 'Factura proforma',
            //     path: '/accounting/proforma'
            // },
            {
                title: 'Aviz',
                category: 'receptie',
                path: '/accounting/avizf'
            },
            {
                title: 'Factura',
                category: 'receptie',
                path: '/accounting/facturaf'
            },
            {
                title: 'Factura proforma',
                category: 'receptie',
                path: '/accounting/proforma'
            },
            {
                title: 'Declaratii vamale',
                category: 'receptie',
                path: '/article/customs'
            },
            {
                title: 'Alt document',
                category: 'receptie',
                path: '/accounting/documentf'
            },
            {
                title: 'Parteneri',
                category: 'receptie',
                path: '/company/partener'
            },
        ]
    },
    {
        title: 'Rapoarte',
        children: [
            {
                title: 'Print Factura',
                path: '/reports/printinvoice'
            },
            {
                title: 'Print Aviz',
                path: '/reports/printaviz'
            },
            {
                title: 'Raport diferente',
                path: '/reports/nirdiferente'
            },
            {
                title: 'Raport calcul pret',
                path: '/reports/nircalculpret'
            },
            {
                title: 'PVR',
                path: '/reports/pvr'
              },
              {
                title: 'PVRF',
                path: '/reports/pvrf'
              },
              {
                title: 'PVRTL',
                path: '/reports/pvrtl'
              }
        ]
    },
    {
        title: 'Modul Gestiunea stocurilor',
        main: true,
        category: 'stocuri',
        children: []
    },
    {
        title: 'Articole',
        code: 'articole',
        category: 'stocuri',
        path: '/article/article',
        children: []
    },
    {
        title: 'Gestionar',
        code: 'gestionar',
        category: 'stocuri',
        path: '/article/gestionar',
        children: []
    },
    {
        title: 'Categorii articole',
        category: 'stocuri',
        path: '/article/categories',
        children: []
    },
    {
        title: 'Gestiuni',
        category: 'stocuri',
        path: '/inventory/inventorylist',
        children: []
    },
    {
        title: 'Fisa',
        code: 'fisa',
        category: 'stocuri',
        path: '/article/fisa',
        children: []
    },
    {
        title: 'BM Comenzi',
        category: 'stocuri-bm-comenzi',
        children: [
            {
                title: 'BOM',
                category: 'stocuri-bm-comenzi',
                path: '/accounting/borderou'
            },
            {
                title: 'Gestionar atelier',
                category: 'stocuri-bm-comenzi',
                path: '/accounting/gestionar_comenzi_atelier'
            },
            {
                title: 'Gestionar magazie',
                category: 'stocuri-bm-comenzi',
                path: '/accounting/gestionar_comenzi_magazie'
            }
        ]
    },
    {
        title: 'BM Regie',
        category: 'stocuri-bm-regie',
        children: [
            {
                title: 'Gestionar atelier',
                category: 'stocuri-bm-regie',
                path: '/accounting/gestionar_regie_atelier'
            },
            {
                title: 'Gestionar magazie',
                category: 'stocuri-bm-regie',
                path: '/accounting/gestionar_regie_magazie'
            }
        ]
    },
    {
        title: 'Rapoarte',
        children: [
          {
            title: 'Bon benzina',
            path: '/reports/bonbenz'
          },
          {
            title: 'Bon fpar',
            path: '/reports/bonfpar'
          },
          {
            title: 'Bon fpcc',
            path: '/reports/bonfpcc'
          },
          {
            title: 'Centru Cost',
            path: '/reports/cencost'
          },
          {
            title: 'Centru profit 921',
            path: '/reports/cenp921'
          },
          {
            title: 'Cheltuieli auto',
            path: '/reports/cheauto'
          },
          {
            title: 'Combustibil benzina',
            path: '/reports/cumbenz'
          },
          {
            title: 'Consum',
            path: '/reports/consum'
          },
          {
            title: 'Fisa s28',
            path: '/reports/fs28'
          },
          {
            title: 'PVDM',
            path: '/reports/pvdm'
          },
          {
            title: 'PVR',
            path: '/reports/pvr'
          },
          {
            title: 'PVRF',
            path: '/reports/pvrf'
          },
          {
            title: 'PVRTL',
            path: '/reports/pvrtl'
          }
    ]
    },
    {
        title: 'Modul Contabilitate',
        main: true,
        category: 'contabilitate',
        children: []
    },
    {
        title: 'Documente',
        category: 'contabilitate',
        children: [
            {
                title: 'Plan conturi (grid)',
                category: 'contabilitate',
                path: '/accounting/conturigrid'
            },
            {
                title: 'Plan conturi (tree)',
                category: 'contabilitate',
                path: '/accounting/conturitree'
            },
            {
                title: 'Nota contabila',
                category: 'contabilitate',
                path: '/accounting/accounting_note'
            },
            {
                title: 'Centru cost',
                category: 'contabilitate',
                path: '/accounting/centru_cost'
            },
            {
                title: 'Centru profit',
                category: 'contabilitate',
                path: '/accounting/centru_profit'
            },
            {
                title: 'Mijloace fixe',
                category: 'contabilitate',
                path: '/accounting/mijloace_fixe'
            },
            {
                title: 'Obiecte inventar',
                category: 'contabilitate',
                path: '/accounting/obiecte_inventar'
            },
            {
                title: 'Formula',
                category: 'contabilitate',
                path: '/formula/formula'
            },
            {
                title: 'Curs',
                category: 'contabilitate',
                path: '/curs/curs'
            }
        ]
    },
    {
        title: 'Rapoarte',
        children: [
            {
                title: 'Jurnal',
                path: '/reports/jurnal'
            },
            {
                title: 'Sah',
                path: '/reports/sah'
            }
        ]
    },
    {
        title: 'Modul Personal',
        main: true,
        category: 'personal',
        children: []
    },
    {
        title: 'Documente',
        category: 'personal',
        children: [
            {
                title: 'Organigrama',
                category: 'personal',
                path: '/personal/organigramatree',
                children: []
            },
            {
                title: 'Program de lucru',
                category: 'personal',
                path: '/personal/program',
            },
            {
                title: 'Pontaj - personal',
                category: 'personal',
                path: '/personal/pontajcalendar',
                children: []
            },
            {
                title: 'Pontaj - departament',
                category: 'personal',
                path: '/personal/pontajcalendardep',
                children: []
            },
            {
                title: 'Pontaj lista status',
                category: 'personal',
                path: '/personal/pontajstatus',
                children: []
            },
            {
                title: 'Pontaj old',
                category: 'personal',
                path: '/personal/pontajold',
                children: []
            },
        ]
    },
    {
        title: 'Rapoarte',
        children: [
            {
                title: 'Pontaj intern',
                path: '/reports/pontajintern'
            },
            {
                title: 'Pontaj extern1',
                path: '/reports/pontajextern1'
            },
        ]
    },
    {
        title: 'Mijloace fixe',
        main: true,
        category: 'mijloace',
        children: []
    },
    {
        title: 'Documente',
        category: 'mijloace',
        children: [
            {
                title: 'Aviz Expeditie',
                category: 'mijloace',
                path: '/accounting/avizexpeditie'
            },
            {
                title: 'Aviz',
                category: 'mijloace',
                path: '/accounting/aviz'
            },
            {
                title: 'Factura proforma',
                category: 'mijloace',
                path: '/accounting/proforma'
            },
            {
                title: 'Factura1',
                category: 'mijloace',
                path: '/accounting/facturac'
            },
            {
                title: 'Factura2',
                category: 'mijloace',
                path: '/accounting/facturaf'
            },
            {
                title: 'Regularizare',
                category: 'mijloace',
                path: '/regularizare/regularizare'
            },
            {
                title: 'Bon fiscal',
                category: 'mijloace',
                path: '/formula/bonfiscal'
            }
        ]
    },
    {
        title: 'Rapoarte',
        children: [
            {
                title: ''
            }
        ]
    },
    {
        title: ' --- de prelucrat ---',
        children: []
    },        
    {
        title: 'Contabilitate',
        children: [
            {
                title: 'Plati-Cumparari',
                path: '/accounting/paybuy'
            },
            {
                title: 'Deconturi',
                path: '/accounting/decont'
            },
            {
                title: 'Bon Material',
                path: '/accounting/bonmaterial'
            },
            {
                title: 'BT Materiale',
                path: '/accounting/btmateriale'
            },
            {
                title: 'BT Inventar',
                path: '/accounting/btinventar'
            },
            {
                title: 'Nota Predare',
                path: '/accounting/notapredare'
            },
            {
                title: 'Nota Restituire',
                path: '/accounting/notarestituire'
            },
            {
                title: 'Cumparari',
                path: '/accounting/buy'
            },
            {
                title: 'Vinzari',
                path: '/accounting/sell'
            }
        ]
    },
    {
        title: 'Rapoarte',
        children: [
            {
                title: 'Gestiuni',
                path: '/reports/inventory'
            },
            {
                title: 'Print Gestiuni',
                path: '/reports/printinventory'
            },
            {
                title: 'Facturi',
                path: '/reports/invoices'
            },
            {
                title: 'Cerere oferta',
                path: '/reports/printcerereoferta'
            },
            {
                title: 'Oferta',
                path: '/reports/oferta'
            },
            {
                title: 'Task',
                path: '/reports/task'
            },
            {
                title: 'Parteneri-divizie',
                path: '/reports/partnerxdivizie'
            },
            {
                title: 'Receptie',
                path: '/reports/receptie'
            },
            {
                title: 'Print Table',
                path: '/reports/printtable'
            },
            {
                title: 'Oferte fara detalii',
                path: '/reports/ofertefd2'
            }
        ]
    }
];

export const menu = {
    listmenu: listmenu
}