import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { BaseService as EwoService } from 'src/app/pages/_ewo/service/service';
import { BaseService } from 'src/app/pages/_services/base.service'

@Component({
  selector: 'response',
  templateUrl: './response.component.html',
  styleUrls: ['./response.component.scss'],
  providers: [EwoService, BaseService]
})
export class ResponseComponent implements OnInit {

  id; submitted;
  responseform: FormGroup;
  entityapi = 'teams';
  tableD = 'teamsd'; tableDH = 'teamsd_history';
  msg;
  flux = { name: '---', description: '---' };
  fluxd;

  parent;

  constructor(
    private serviceBase1: EwoService,
    private router: Router,
    private route: ActivatedRoute,
    private service: BaseService,
    public formBuilder: FormBuilder) { }

  ngOnInit() {

    let self = this;
    this.responseform = this.formBuilder.group({
      emails: [''],
      response: ['', [Validators.required]]
    });

    this.route.params.subscribe(params => {
      if (params['id']) {
        this.id = params['id'];

        this.service.getItemEntity(this.tableD, this.id).subscribe(data => {

          if (data) {
            this.fluxd = data[0];
            this.responseform.controls['response'].setValue(this.fluxd['response'])
            this.responseform.controls['emails'].setValue(this.fluxd['emails'])

            if (this.fluxd['parentid']) {
              this.service.getItemEntity(this.tableD, this.fluxd['parentid']).subscribe(data2 => {
                if (data2) {
                  this.parent = data2[0];
                }
              })
            }

            this.service.getItemEntity(this.entityapi, data[0]['docid']).subscribe(data2 => {
              if (data2)
                this.flux = data2[0];
            });
          }
        });
      }
    });
  }

  save(data) {
    this.submitted = true;

    if (!data['response'])
      return;

    this.service.updateItemEntity(this.tableD, this.id, { id: this.id, date: new Date(), response: data['response'], emails: data['emails'] }).subscribe(all => {
      this.service._createItemEntity(this.tableDH, { id: this.guid(), teamsdid: this.id, date: new Date(), response: data['response'], emails: data['emails'] }).subscribe(all => {
        this.msg = "Salvat cu succes!"

        this.serviceBase1.sendMailConfirmare(this.fluxd).then(all2 => {

      })

      });
    });
  }

  get form() {
    return this.responseform.controls;
  }


  s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  guid() {

    return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' +
      this.s4() + '-' + this.s4() + this.s4() + this.s4();
  }


}
